<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmaqidescription.formtitle')">
					<v-row no-gutters>
						<v-col cols="12" md="12" lg="5" xl="6">
							<v-row no-gutters>
								<v-col cols="12">
									<pui-text-field
										:label="$t('pmaqidescription.name')"
										v-model="model.name"
										toplabel
										required
										maxlength="150"
									></pui-text-field>
								</v-col>
							</v-row>
							<v-row no-gutters>
								<v-col cols="12">
									<pui-text-area
										v-model="model.description"
										:label="$t('pmaqidescription.description')"
										maxlength="250"
										toplabel
									></pui-text-area>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" md="12" lg="7" xl="6">
							<v-row no-gutters>
								<v-col cols="12" md="6">
									<pui-field-set :title="$t('pmaqidescription.colorsat')" class="d-flex justify-center align-center">
										<v-color-picker
											v-model="model.colorsat"
											dot-size="15"
											hide-mode-switch
											mode="hexa"
											canvas-height="100"
											:label="$t('pmstatus.color')"
											class="my-2"
											required
										></v-color-picker>
									</pui-field-set>
								</v-col>
								<v-col cols="12" md="6">
									<pui-field-set :title="$t('pmaqidescription.colorcalc')" class="d-flex justify-center align-center">
										<v-color-picker
											v-model="model.colorcalc"
											dot-size="15"
											hide-mode-switch
											mode="hexa"
											canvas-height="100"
											:label="$t('pmstatus.color')"
											class="my-2"
											required
										></v-color-picker>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="checkColor"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
//import constants from '@/utils/constants';
import { isSuperAdmin } from '../../api/common';

export default {
	name: 'pmaqidescriptionform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmaqidescription',
			model: 'pmaqidescription',
			isSuperAdmin: false,
			colorTemp: null,
			saveButton: null
		};
	},
	watch: {
		'model.color': {
			handler(newValue, oldValue) {
				console.log('debug color', oldValue, newValue);
				if (newValue && typeof newValue == 'object') {
					this.model.color = this.model.color.hex;
				}
			}
		}
		/* model: {
			handler: function () {
				console.log('colorTemp: ', this.colorTemp);
				console.log('model.color: ', this.model.color);
				this.saveButton = this.saveButton ?? document.getElementsByClassName('pui-form-footer-btns__desktop')[0];

				if (this.model.color && this.saveButton) {
					this.saveButton.addEventListener('mouseenter', this.colorError);
					if (!this.model.color.startsWith('#')) {
						this.colorTemp = `#${this.model.color}`;
						this.model.color = this.colorTemp;
						console.log('bad color');
					} else {
						if (this.model.color.length == 7 || this.model.color.length == 4) {
							if (!/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(this.model.color)) {
								this.$puiNotify.info(this.$t('pmaqidescription.noValidColor'));
							} else {
								this.model.color = this.model.color.toUpperCase();
							}
						} else if (/^#[^0-9a-fA-F]/g.test(this.model.color)) {
							this.$puiNotify.info(this.$t('pmaqidescription.noValidColor'));
						}
					}
				} else if (this.saveButton) {
					this.saveButton.removeEventListener('mouseenter', this.colorError);
				}
			},
			deep: true
		}, */
	},
	computed: {},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.infoColorCode = this.$t('pmaqidescription.infoColorCode');
	},
	updated() {
		if (this.model) {
			if (!this.modelLoaded) {
				this.modelLoaded = true;
			}
		}
	},
	methods: {
		colorError() {
			if (!/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(this.model.color)) {
				this.$puiNotify.info(this.$t('pmaqidescription.noValidColor'));
				this.model.color = null;
			} else {
				this.model.color = this.model.color.toUpperCase();
			}
		},
		checkColor() {
			if (this.model.colorsat.length == 9) {
				this.model.colorsat = this.model.colorsat.substring(0, 7);
			}
			if (this.model.colorcalc.length == 9) {
				this.model.colorcalc = this.model.colorcalc.substring(0, 7);
			}
			this.save();
		}
	}
};
</script>
